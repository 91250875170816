<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="bank_transfers"
                          columnsPrefix="bank_transfers.column."
                          base-table="bank_transactions"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.menu.account-book.bank-transfers"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form-checkbox
                            class="ml-2"
                            v-model="includeAssigned"
                            switch
                            value="1"
                            unchecked-value="0"
                            @change="includeAssignedChanged"
                        >{{ $t('bank.label.include_assigned') }}
                        </b-form-checkbox>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                v-if="!row.item.assigned"
                                @click="$root.$children[0].openModal('transfer-assign-modal', {id:row.item.id, transfer:row.item.transfer_data}, refreshTable, {width: '800px'})"
                            >
                                {{ $t('bank.actions.assign_kva') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!row.item.assigned"
                                @click="$root.$children[0].openModal('assign-invoice-modal', {id:row.item.id, transfer:row.item.transfer_data}, refreshTable)"
                            >
                                {{ $t('bank.actions.assign_invoice') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="row.item.assigned"
                                @click="unlink(row.item.id)"
                            >
                                {{ $t('bank.actions.unlink') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!row.item.assigned"
                                @click="deleteTransfer(row.item.id)"
                            >
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.button.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'BankTransfers',
    mixins: [commonTable],
    data() {
        return {
            tableOptions: {
                sortBy: 'date_time',
                sortDesc: true,
            },
            includeAssigned: false,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('BankTransfers', ['getTransfersTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'BankTransfers/fetchTransfers', 'getTransfersTable', {}, (tableData) => {
                if (tableData.params.filter.include_assigned) {
                    this.includeAssigned = tableData.params.filter.include_assigned
                }
            });
        },
        ctxAdditionalParams() {
            return {
                'include_assigned': this.includeAssigned
            }
        },
        unlink(id) {
            this.$store.dispatch('BankTransfers/unlink', {id}).finally(() => {
                this.refreshTable()
            })
        },
        deleteTransfer(id) {
            this.$store.dispatch('BankTransfers/delete', id)
                .finally(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.deleted'),
                        'success'
                    )
                    this.refreshTable()
                })
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.import'),
                    icon: 'download',
                    click: () => {
                        this.$root.$children[0].openModal('import-modal', {type: 'debitBankTransfers'}, this.refreshTable, {width: '800px'})
                    }
                },
                {
                    title: this.$t('bank.button.sync_transactions'),
                    icon: 'sync',
                    click: () => {
                        this.$root.$children[0].openModal('bank-transfers', {}, null, {width: '800px'})
                    }
                },
                {
                    title: this.$t('bank.button.delete'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'trash',
                    click: () => {
                        this.$store.dispatch('BankTransfers/deleteCredit', this.getSelectedData('bank_transfers'))
                            .then((response) => {
                                this.$root.showMessage(
                                    this.$t('common.form.title.success'),
                                    this.$t('common.toasts.deleted'),
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                }
                // {
                //     title: this.$t('bank.button.map_transactions'),
                //     disabled: !(this.selected.length || this.selectedSource != 'selected'),
                //     icon: 'list',
                //     click: () => {
                //         this.$store.dispatch('BankTransfers/mapTransactions', this.getSelectedData('bank_transfers'))
                //             .then((response) => {
                //                 this.$root.$children[0].showJobsToast(response.data.batch_group)
                //             })
                //             .finally(() => {
                //                 this.loading = false
                //             })
                //     }
                // }
            ]
        },
        includeAssignedChanged(val) {
            this.$set(this.filter, 'include_assigned', val)
            this.$set(this.filter, 'filterChanged', true)
        },
    },
}
</script>